export default {
  name: 'SendTicket',
  data() {
    return {
      modal: false,
      ticket: {
        email: '',
        fname_lname: '',
        subdomain: '',
        asunto: ''
      }
    }
  },
  methods: {
    save() {
      this.$refs.send_ticket.validate().then(result => {
        if (result) {
          this.ticket.language = this.$i18n.locale
          this.axios.post(this.$store.getters.getTicket(), this.ticket)
            .then((response) => {
              if (response.status == 201) {
                this.clean()
                this.modal = false
                this.$awn.success(this.$t('messages.ticket_success'))
              }
            })
            .catch((error) => {
              console.error(error.response)
            })
        }
      })
    },
    close() {
      this.modal = false
      this.$refs.send_ticket.reset()
    },
    clean() {
      this.ticket = {
        email: '',
        fname_lname: '',
        subdomain: '',
        asunto: ''
      }
      this.$refs.send_ticket.reset()
    }
  }
}