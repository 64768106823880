<template>
  <v-container fluid fill-height pa-0>
    <v-row class="text-center container">
      <v-col class="col col-12 pa-0">
        <h1 class="display-2 font-weight-bold">
          {{ app_name }}
        </h1>
        <div class="pt-8 mx-auto container">
          {{ $t('home_intro') }}
        </div>
        <send-ticket />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SendTicket from '../../components/ticket/SendTicket.vue'

export default {
  components: {
    SendTicket
  },
  data: () => ({
    app_name: process.env.VUE_APP_NAME
  }),
  mounted() {
    if(this.$store.getters.isAdmin || this.$store.getters.isOperator){
      this.$router.push("/dashboard")
    }
  }
}
</script>
