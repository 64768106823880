var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.modal = true}}},[_vm._v(" "+_vm._s(_vm.$t('actions.send'))+" "+_vm._s(_vm.$t('report'))+" ")])]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(" "+_vm._s(_vm.$t('actions.send'))+" "+_vm._s(_vm.$t('report'))+" ")]),_c('v-card-text',{staticClass:"pt-5"},[_c('ValidationObserver',{ref:"send_ticket",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"vid":"email","name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{key:"email-input",staticClass:"secondary--text",attrs:{"validate-on-blur":"","name":"email","error-messages":errors[0],"label":_vm.$tc('user.email')},model:{value:(_vm.ticket.email),callback:function ($$v) {_vm.$set(_vm.ticket, "email", $$v)},expression:"ticket.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"vid":"fname_lname","name":"fname_lname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{key:"fname_lname-input",staticClass:"secondary--text",attrs:{"validate-on-blur":"","name":"fname_lname","error-messages":errors[0],"label":_vm.$t('ticket.fullname')},model:{value:(_vm.ticket.fname_lname),callback:function ($$v) {_vm.$set(_vm.ticket, "fname_lname", $$v)},expression:"ticket.fname_lname"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"vid":"subdomain","name":"company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{key:"subdomain-input",staticClass:"secondary--text",attrs:{"validate-on-blur":"","name":"subdomain","error-messages":errors[0],"label":_vm.$t('ticket.company')},model:{value:(_vm.ticket.subdomain),callback:function ($$v) {_vm.$set(_vm.ticket, "subdomain", $$v)},expression:"ticket.subdomain"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"vid":"subject","name":"subject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{key:"subject-input",staticClass:"secondary--text",attrs:{"validate-on-blur":"","name":"subject","error-messages":errors[0],"label":_vm.$t('ticket.subject')},model:{value:(_vm.ticket.asunto),callback:function ($$v) {_vm.$set(_vm.ticket, "asunto", $$v)},expression:"ticket.asunto"}})]}}],null,true)})],1)],1)],1)]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t('actions.close'))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t('actions.send'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }